<p><img src="/assets/chris-smith-lisbon.jpeg" alt="Chris' avatar" /></p>
<h1>Chris Smith</h1>
<p>Hi, I'm Chris. I'm a full-stack Rust engineer at WalletConnect.</p>
<dl>
  <dt>Twitter</dt>
  <dd><a href="https://twitter.com/mechris13524" routerLinkActive="_link-active">mechris13524</a></dd>
  <div></div>
  <dt>email</dt>
  <dd><a href="mailto:chris@smith.xyz" routerLinkActive="_link-active">chris@smith.xyz</a></dd>
  <dt>LinkedIn</dt>
  <dd><a href="https://www.linkedin.com/in/chris13524/" routerLinkActive="_link-active">chris13524</a></dd>
  <div></div>
  <dt>GitHub</dt>
  <dd><a href="https://github.com/chris13524" routerLinkActive="_link-active">chris13524</a></dd>
  <dt>GitLab</dt>
  <dd><a href="https://gitlab.com/chris13524" routerLinkActive="_link-active">chris13524</a></dd>
  <div></div>
  <dt>SSH</dt>
  <dd><a routerLink="/ssh" routerLinkActive="_link-active">key</a></dd>
  <dt>GPG</dt>
  <dd><a routerLink="/gpg" routerLinkActive="_link-active">key</a></dd>
  
</dl>
